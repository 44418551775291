import validate from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45view_45tracker_45global from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/middleware/pageViewTracker.global.ts";
import tenant_45auth_45global from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/middleware/tenantAuth.global.ts";
import manifest_45route_45rule from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45view_45tracker_45global,
  tenant_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/middleware/auth.ts"),
  checkout: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/middleware/checkout.ts"),
  "processing-transaction": () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/middleware/processing-transaction.ts")
}